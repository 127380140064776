import React, { useState, useEffect } from "react";
import { message, Icon } from "antd";
import { Form, Input, Button } from "antd";
import "./Profile.css";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const useProfileLogic = () => {
    const history = useHistory()
    const [selectedOption, setSelectedOption] = useState("profile");
    const [userdata, setUserdata] = useState({});
    const [visible, setVisible] = useState(false);
    const [currentpassword, setCurrentpassword] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [email, setEmail] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [changepasswordvisible, setChangepasswordvisible] = useState(false);

    useEffect(() => {
        if (!localStorage.usertoken) {
            history.push(`/`);
        }
        const email = localStorage.getItem("useremail");
        Axios.get("/users/userDetails/" + email)
            .then((res) => {
                const { first_name, last_name, email, contactNumber } = res.data;
                setUserdata(res.data);
                setFirstName(first_name);
                setLastName(last_name);
                setEmail(email);
                setContactNumber(contactNumber);
            })
            .catch((e) => console.log(e));
    }, [history]);

    const currentpass = (e) => setCurrentpassword(e.target.value);
    const newpass = (e) => setNewpassword(e.target.value);

    const showModal = () => setVisible(true);

    const handleOk = () => {
        Axios.post("/users/changePassword", {
            email: userdata.email,
            password: currentpassword,
            newPassword: newpassword,
        })
            .then((res) => {
                if (res.data === "Success") {
                    message.success("Password updated");
                    setVisible(false);
                    setCurrentpassword("");
                    setNewpassword("");
                } else {
                    message.error("Wrong Password");
                    setCurrentpassword("");
                    setNewpassword("");
                }
            })
            .catch((e) => console.log(e));
    };

    const handleCancel = () => setVisible(false);

    const changeEmail = (e) => setEmail(e.target.value);
    const changeFirstName = (e) => setFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);

    const changeNumber = (value) => setContactNumber(value);

    const updateUser = () => {
        Axios.put("/users/editUser", {
            // prevemail: 'tushar@innovaara.com',
            // email: 'disabled_tusha2r@innovaara.com',
            // firstName: 'Tushar',
            // lastName: 'Habib',
            // contactNumber,
            prevemail: userdata.email,
            email,
            firstName: first_name,
            lastName: last_name,
            contactNumber,
        })
            .then((res) => {
                if (res.data.status === "success") {
                    message.success("Profile is updated");
                    const data = res.data.userdata;
                    setUserdata(data);
                    setFirstName(data.first_name);
                    setLastName(data.last_name);
                    setEmail(data.email);
                    setContactNumber(data.contactNumber);
                    localStorage.setItem("useremail", data.email);
                    localStorage.setItem(
                        "userdata",
                        JSON.stringify({
                            first_name: data.firstName,
                            last_name: data.lastName,
                            contactNumber: data.contactNumber,
                        })
                    );
                } else {
                    message.error("Fail to update profile");
                    setFirstName(userdata.first_name);
                    setLastName(userdata.last_name);
                    setEmail(userdata.email);
                    setContactNumber(userdata.contactNumber);
                }
            })
            .catch((e) => console.log(e));
    };

    return {
        first_name,
        changeFirstName,
        last_name,
        changeLastName,
        contactNumber,
        changeNumber,
        email,
        changeEmail,
        updateUser,
        changepasswordvisible,
        setChangepasswordvisible,
        currentpass,
        currentpassword,
        newpassword,
        newpass,
        handleOk,
    }
}

export default useProfileLogic