import React from "react";
import "./Home.css";
import moment from "moment";
import { ROOMS_LOCATION } from "constants/RouteNames";

const initialPanes = [
  {
    key: "1",
    adults: 1,
    children: 0,
    childrenInfo: [],
    closable: false,
  },
];

export const PopularDestinations = (props) => {
  const {
    place,
    placeId = undefined,
    currency,
    cost,
    url,
    imageClass,
  } = props
  const search = (event) => {
    event.preventDefault();

    // if( !localStorage.usertoken || !localStorage.userdata ) {
    //   console.log('props', props)
    //   props.showLogin();
    // }else{
    let start = new moment(new Date().setDate(new Date().getDate() + 1));
    let end = new moment(new Date().setDate(new Date().getDate() + 2));

    const placeName = place.replaceAll(', ', '-').replaceAll(' ', '_');

    props.history.push({
      pathname: ROOMS_LOCATION.replace(':placeId', placeId).replace(':location', placeName),
      state: {
        place: place,
        placeId,
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD"),
        occupancies: [{ numOfAdults: 1 }],
        noofAdults: 1,
        noofChild: 0,
        noofroom: 1,
        panes: [initialPanes]
      },
    });
    // }
  };

  return (
    <div className="PopularDestination">
      <div
        className={imageClass}
        style={{ backgroundImage: `url( ${url} )` }}
        onClick={search}
      />
      <div className="text" onClick={search}>
        {place}
      </div>
      {/* <div className="subText" onClick={search}>
        Prices from {currency} {cost}
      </div> */}
    </div>
  );
};
