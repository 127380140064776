import React, { useState } from "react";
import { Menu, Spin } from "antd";
import Header from "components/Header/Header";
import { AllBookings } from "pages/Booking/AllBookings";
import { useEffect } from "react";
import Axios from "axios";
import { INDOSAT_B2B_TEAM_MEMBERS_ROLES } from "helpers/CheckIndosatTeamMemberRolesHelper";
import "pages/Auth/Profile/Profile.css";
import Profile from "pages/Auth/Profile/Profile";
import FooterPage from "components/Global/FooterPage/FooterPage";
import DashboardContentRoaming from "components/Provider/Business/DashboardContentRoaming";

const MyCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const email = localStorage.getItem("useremail");
    Axios.get("/users/userDetails/" + email)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (!isLoading && data) {
    return (
      <div className="profileouterContainer">
        <h2>Company Info</h2>
        <p>Company name: {data.company?.companyName}</p>
        <p>Company business: {data.company?.businessName}</p>
        {
          data?.company && data.company.picId !== data.id && (
            <>
              <hr />
              <h2>Your roles</h2>
              <ul style={{
                marginLeft: '30px'
              }}>
                {data.teamMembers?.role &&
                  data.teamMembers.role.map((roleId) => (
                    <li key={roleId}>
                      {INDOSAT_B2B_TEAM_MEMBERS_ROLES.find((role) => role.id === roleId)?.description}
                    </li>
                  ))}
              </ul>
            </>
          )
        }

        {data?.company && data.company.picId == data.id && (
          <h2>Your role: PIC</h2>
        )}
      </div>
    );
  }

  // Loading state or data is still being fetched
  return <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: '100px 0',
    }}
  >
    <Spin size="large" />
  </div>
};

class Profilenavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.getCurrentTab(), // Set initial tab based on URL parameter
    };
    this.handleClick = this.handleClick.bind(this);
  }

  getCurrentTab() {
    const params = new URLSearchParams(window.location.search);
    return params.get("tab") || "profile"; // Default to "profile" if no tab param is present
  }

  handleClick(e) {
    this.setState({ current: e.key });
  }

  goBack = () => {
    this.props.history.goBack(); // Go back to the previous page
  };

  render() {
    // Define a mapping of tab keys to their corresponding components
    const tabComponents = {
      profile: <Profile />,
      booking: <AllBookings />,
      your_company: <MyCompany />, 
      roaming_info: <DashboardContentRoaming />, 
    };
    const userData = JSON.parse(localStorage.getItem("userdata"));
    return (
      <div>
        <Header />

        <div className="menuContainer">
          <a href="javascript:void(0)" style={{ color: '#f48148' }} onClick={this.goBack}>&lt; Back to Home</a>
        </div>

        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
          className="menuContainer"
        >

          <Menu.Item className="menuItem" key="profile">
            Profile
          </Menu.Item>
          <Menu.Item className="menuItem" key="booking">
            Booking
          </Menu.Item>
          {userData.companyData !== null &&
            <Menu.Item className="menuItem" key="your_company">
              Your company
            </Menu.Item>
          }
          {userData.companyData !== null &&
             <Menu.Item className="menuItem" key="roaming_info">
             Roaming info
           </Menu.Item>
          }
        </Menu>
        {tabComponents[this.state.current]}
        <FooterPage />
      </div>
    );
  }
}

export default Profilenavbar;
