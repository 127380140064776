import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { SearchBarDesktop } from "./SearchBarDesktop";
import SearchBarMobile from "./SearchBarMobile";
import { removeStorage, } from "helpers/LocalStorageHelpers";
import { ROOMS_LOCATION } from "constants/RouteNames";
import moment from "moment";

export default class SearchBar extends Component {
  constructor(props) {
    super(props);

    const queryString = props?.history?.location?.search;
    const queryParams = {};
    queryString.slice(1).split('&').forEach(query => {
      const [key, value] = query.split('=');
      queryParams[key] = value;
    });
    const p_id = queryParams.p_id;

    this.state = {
      placeId: props.history.location?.state?.placeId ?? p_id ?? null,
      place: props.place ? props.place : "",
      startDate: props.startDate
        ? props.startDate
        : moment().add(1, "days").format("YYYY-MM-DD"),
      endDate: props.endDate
        ? props.endDate
        : moment().add(2, "days").format("YYYY-MM-DD"),
      activeKey: "1",
      panes: props.panes
        ? props.panes
        : [
          {
            key: "1",
            adults: 1,
            children: 0,
            childrenInfo: [],
            closable: false,
          },
        ],
      totalAdults: props.totalAdults ? props.totalAdults : 1,
      totalChildren: props.totalChildren ? props.totalChildren : 0,
      totalRooms: props.totalRooms ? props.totalRooms : 1,
    };
  }

  newTabIndex = 0;

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = () => {
    const { panes } = this.state;
    const activeKey = `newTab${this.newTabIndex++}`;
    const newPanes = [...panes];
    newPanes.push({
      adults: 1,
      children: 0,
      childrenInfo: [],
      key: activeKey,
    });
    this.setState((prevState) => ({
      panes: newPanes,
      activeKey,
      totalRooms: prevState.totalRooms + 1,
      totalAdults: prevState.totalAdults + 1,
    }));
  };

  remove = (targetKey) => {
    const { panes, activeKey } = this.state;
    let newActiveKey = activeKey;
    let lastIndex;

    let newTotalAdults = this.state.totalAdults;
    let newTotalChildren = this.state.totalChildren;

    for (let itr in panes) {
      if (panes[itr].key === targetKey) {
        newTotalAdults -= panes[itr].adults;
        newTotalChildren -= panes[itr].children;
        break;
      }
    }

    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = panes.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    this.setState({
      panes: newPanes,
      activeKey: newActiveKey,
      totalRooms: this.state.totalRooms - 1,
      totalAdults: newTotalAdults,
      totalChildren: newTotalChildren,
    });
  };

  changeAdultCount = (newCount, key) => {
    let newPanes = [...this.state.panes];
    let newTotalAdults = this.state.totalAdults;
    for (let itr in newPanes) {
      if (key === newPanes[itr].key) {
        if (newCount < this.state.panes[itr].adults) {
          newTotalAdults--;
        } else {
          newTotalAdults++;
        }
        newPanes[itr].adults = newCount;
      }
    }

    this.setState({ panes: newPanes, totalAdults: newTotalAdults });
  };

  changeChildCount = (newCount, key) => {
    let newPanes = [...this.state.panes];
    let newTotalChildren = this.state.totalChildren;
    for (let itr in newPanes) {
      if (key === newPanes[itr].key) {
        if (newCount < this.state.panes[itr].children) {
          newPanes[itr].childrenInfo.pop();
          newTotalChildren--;
        } else {
          newPanes[itr].childrenInfo.push(6);
          newTotalChildren++;
        }
        newPanes[itr].children = newCount;
      }

      this.setState({ panes: newPanes, totalChildren: newTotalChildren });
    }
  };

  changeChildAge = (value, index, key) => {
    let newPanes = [...this.state.panes];

    for (let itr in newPanes) {
      if (key === newPanes[itr].key) {
        newPanes[itr].childrenInfo[index] = value;
      }
    }

    this.setState({ panes: newPanes });
  };

  clickPlace = (event, otherVal, activeSuggestion = 0) => {
    let place = otherVal !== undefined ? otherVal : event.target.value;
    this.setState({ place, activeSuggestion });
  };

  setplace = (place) => this.setState({ place });


  changeDate = (date, dateString) => {
    this.setState({
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
    });
  };

  // changeDateMobile = (date, type) => {
  //   if (type === "start") {
  //     this.setState({
  //       startDate: date.format("YYYY-MM-DD"),
  //     });
  //   } else {
  //     this.setState({
  //       endDate: date.format("YYYY-MM-DD"),
  //     });
  //   }
  // };

  changeDateMobile = (date, type) => {
    if (type === "start") {
      this.setState({
        startDate: date.format("YYYY-MM-DD"),
        // Automatically adjust the endDate to the next day after the startDate
        endDate: date.clone().add(1, 'day').format('YYYY-MM-DD'),
      });
    } else {
      this.setState({
        endDate: date.format("YYYY-MM-DD"),
      });
    }
  };


  children = (count) => {
    switch (count) {
      case 0:
        return "No Child";
      case 1:
        return "1 Child";
      default:
        return `${count} Children`;
    }
  };



  doSearch = () => {
    if (this.state.place) {
      removeStorage("_search_result_key");
      removeStorage("_search_result_token");
      const occupy = [];
      this.state.panes.forEach((item) => {
        occupy.push({
          numOfAdults: item.adults,
          childAges: item.childrenInfo,
        });
      });

      // Check date
      // Get today's date
      const today = moment().startOf('day');

      // Create moment objects for start and end dates
      let startDate = moment(this.state.startDate);
      let endDate = moment(this.state.endDate);

      console.log(startDate, today,)

      // Check if the start date is today
      if (startDate.isSame(today, 'day')) {
        // Add one day to the start date and two days to the end date
        startDate.add(1, 'days');
        endDate.add(2, 'days');
      }

      const placeName = this.state.place.replaceAll(', ', '-').replaceAll(' ', '-');

      const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

      this.props.history.push({
        pathname: `${ROOMS_LOCATION.replace(':location', placeName).replace(':placeId', this.state.activeSuggestion ?? this.state.placeId ?? undefined)}`,
        // pathname: `${ROOMS_LOCATION.replace(':location', placeName).replace(':placeId', this.state.activeSuggestion ?? this.state.placeId ?? undefined)}${providerQueryParam !== '' ? '?provider=business' : ''}`,
        state: {
          hasProvider: hasProvider ?? false,
          place: this.state.place,
          placeId: this.state.placeId ?? undefined,
          activeSuggestion: this.state.activeSuggestion ?? undefined,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          // startDate: this.state.startDate,
          // endDate: this.state.endDate,
          occupancies: occupy,
          noofAdults: this.state.totalAdults,
          noofChild: this.state.totalChildren,
          noofroom: this.state.totalRooms,
          panes: [this.state.panes],
        },
      });
    } else {
      alert('Please key in your prefered location.');
    }
  }

  search = () => {
    // if( !localStorage.usertoken || !localStorage.userdata ){
    //   this.props.showLogin();
    // }else{
    //   this.doSearch()
    // }

    this.doSearch();
  };

  render() {
    return (
      <div>
        <MediaQuery maxWidth={775}>
          <SearchBarMobile
            state={this.state}
            setplace={this.setplace}
            clickPlace={this.clickPlace}
            changeDate={this.changeDateMobile}
            children={this.children}
            changeAdultCount={this.changeAdultCount}
            changeChildCount={this.changeChildCount}
            changeChildAge={this.changeChildAge}
            onChange={this.onChange}
            onEdit={this.onEdit}
            search={this.search}
            roomInfoType={
              this.props.roomInfoType ? this.props.roomInfoType : "modal"
            }
            containerStyle={this.props.containerStyle}
            setContainingModal={this.props.setContainingModal}
          />
        </MediaQuery>
        <MediaQuery minWidth={776}>
          <SearchBarDesktop
            state={this.state}
            setplace={this.setplace}
            clickPlace={this.clickPlace}
            changeDate={this.changeDate}
            children={this.children}
            changeAdultCount={this.changeAdultCount}
            changeChildCount={this.changeChildCount}
            changeChildAge={this.changeChildAge}
            onChange={this.onChange}
            onEdit={this.onEdit}
            search={this.search}
          />
        </MediaQuery>
      </div>
    );
  }
}
